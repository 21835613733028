import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';

export default function ResetPassword() {
  const { id, token } = useParams();
  const [passwords, setPasswords] = useState();
  const navigate = useNavigate();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setPasswords((prevPasses) => ({
      ...prevPasses,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    axios
      .post(
        `https://church.webions.co.uk/backend/api/user/reset-password/${id}/${token}/`,
        passwords,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      )
      .then((response) => {
        Swal.fire({
					title: 'Success',
					text: response.data.msg,
					icon: 'success',
					confirmButtonText: 'Okay'
				  });
        // alert(response.data.msg);
        window.location.href = "/Signin"
      });
  };
  return (
    <>
      <div
        className="sign section--full-bg header_signing"
        data-bg="/img/bg1.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sign__content">
                <form action="#" className="sign__form">
                  <a href="index.html" className="sign__logo">
                    <img src="/img/church-logo.png" alt="" />
                  </a>
                  <span className="sign__text">Set New Password</span>

                  <div className="sign__group">
                    <input
                      type="password"
                      name="password"
                      onChange={handleChange}
                      value={passwords?.password}

                      className="sign__input"
                      placeholder="Password"
                    />
                  </div>
                  <div className="sign__group">
                    <input
                      type="password"
                      name="password2"
                      onChange={handleChange}
                      value={passwords?.password2}
                      className="sign__input"
                      placeholder="Confirm Password"
                    />
                  </div>

                  <button
                    className="sign__btn"
                    onClick={handleSubmit}
                    type="button"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
