import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function Users() {
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; 
  const [searchTerm, setSearchTerm] = useState("");
  const [check, setCheck] = useState(0);
  useEffect(() => {
    axios.get("https://church.webions.co.uk/backend/api/user/").then(function (response) {
      setUserData(response.data);
    });
  }, [check]);
  const setApproval = async (id) => {
    try {
      setLoading(true)
      await axios.post(`https://church.webions.co.uk/backend/api/user/approve/${id}/`)
      setLoading(false)
      setUserData((prevUserData) =>
        prevUserData.map((user) =>
          user.id === id ? { ...user, status: "approved" } : user
        )
      );
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error,
        icon: 'error',
        confirmButtonText: 'Okay'
        });
    }
  };
  const deleteUser = async (id) => {
    try {
      await axios.delete(`https://church.webions.co.uk/backend/api/user/${id}/`);
      Swal.fire({
        title: "Success",
        text: "User has been Deleted",
        icon: "success",
        confirmButtonText: "Okay",
      });
      setUserData((prev) => prev.filter((user) => user.id !== id));
      setCheck((prev) => prev + 1);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error,
        icon: 'error',
        confirmButtonText: 'Okay'
        });
    }
  };
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Confirm Delete?",
      text: "Are you Sure you want to Delete this User?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Sure",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(id);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [loading,setLoading] = useState()

  const filteredData = userData.filter((user) =>
    `${user.first_name} ${user.last_name}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      {/* <!-- main content --> */}
      <main className="main">
        <div className="container-fluid">
          <div className="row">
            {/* <!-- main title --> */}
            <div className="col-12">
              <div className="main__title">
                <h2>Users</h2>

                <span className="main__title-stat">
                  {userData?.length} total
                </span>

                <div className="main__title-wrap">
                  {/* <!-- filter sort --> */}
                
                  {/* <!-- end filter sort --> */}

                  {/* <!-- search --> */}
                  <form action="#" className="main__title-form">
                    <input
                      type="text"
                      placeholder="Find user.."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button type="button">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="8.25998"
                          cy="8.25995"
                          r="7.48191"
                          stroke="#2F80ED"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></circle>
                        <path
                          d="M13.4637 13.8523L16.3971 16.778"
                          stroke="#2F80ED"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </button>
                  </form>
                  {/* <!-- end search --> */}
                </div>
              </div>
            </div>
            {/* <!-- end main title --> */}

            {/* <!-- users --> */}
            <div className="col-12">
              <div className="main__table-wrap">
                <table className="main__table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>BASIC INFO</th>

                      <th>STATUS</th>
                      <th>CREATED DATE</th>
                      <th>ACTIONS</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentItems?.map((data) =>
                      data.is_admin ? (
                        <></>
                      ) : (
                        <>
                          <tr style={{borderBottom:'10px solid #131720'}}>
                            <td style={{backgroundColor:'#151f30' ,padding:'0 10px'}}>
                              <div className="main__table-text">{data.id}</div>
                            </td>
                            <td style={{backgroundColor:'#151f30'}}>
                              <div className="main__user">
                                <div className="main__avatar">
                                  <img src="/img/user.svg" alt="" />
                                </div>
                                <div className="main__meta">
                                  <h3>{`${data.first_name}  ${data.last_name}`}</h3>
                                  <span>{data.email}</span>
                                </div>
                              </div>
                            </td>

                            <td style={{backgroundColor:'#151f30'}}>
                              <div
                                className={`main__table-text ${
                                  data.status === "approved"
                                    ? "main__table-text--green"
                                    : "main__table-text--red"
                                }`}
                              >
                                {data?.status}
                              </div>
                            </td>
                            <td style={{backgroundColor:'#151f30'}}>
                              <div className="main__table-text">
                                {(() => {
                                  const createdAtDate = new Date(
                                    data.created_at
                                  );

                                  const year = createdAtDate.getFullYear();
                                  const month = createdAtDate.toLocaleString(
                                    "default",
                                    { month: "short" }
                                  );
                                  const day = createdAtDate.getDate();

                                  return `${year} ${month}, ${day}`;
                                })()}
                              </div>
                            </td>
                            <td style={{backgroundColor:'#151f30'}}>
                              <div className="main__table-btns">
                                {data.status == "pending" ? (
                                  <a
                                    onClick={() => setApproval(data.id)}
                                    className="main__table-btn main__table-btn--banned"

                                    style={{ cursor: "pointer", pointerEvents:`${loading? 'none' : ''}` }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M12,13a1.49,1.49,0,0,0-1,2.61V17a1,1,0,0,0,2,0V15.61A1.49,1.49,0,0,0,12,13Zm5-4V7A5,5,0,0,0,7,7V9a3,3,0,0,0-3,3v7a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V12A3,3,0,0,0,17,9ZM9,7a3,3,0,0,1,6,0V9H9Zm9,12a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H17a1,1,0,0,1,1,1Z" />
                                    </svg>
                                  </a>
                                ) : (
                                  <></>
                                )}
                                <Link
                                  to={`/admin/Edit_user/${data.id}`}
                                  className="main__table-btn main__table-btn--edit"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M22,7.24a1,1,0,0,0-.29-.71L17.47,2.29A1,1,0,0,0,16.76,2a1,1,0,0,0-.71.29L13.22,5.12h0L2.29,16.05a1,1,0,0,0-.29.71V21a1,1,0,0,0,1,1H7.24A1,1,0,0,0,8,21.71L18.87,10.78h0L21.71,8a1.19,1.19,0,0,0,.22-.33,1,1,0,0,0,0-.24.7.7,0,0,0,0-.14ZM6.83,20H4V17.17l9.93-9.93,2.83,2.83ZM18.17,8.66,15.34,5.83l1.42-1.41,2.82,2.82Z" />
                                  </svg>
                                </Link>
                                <a
                                  href="#"
                                  className="main__table-btn main__table-btn--delete"
                                  onClick={()=>handleDelete(data.id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" />
                                  </svg>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <!-- end users --> */}

            {/* <!-- paginator --> */}
            <div className="col-12">
              <div className="paginator">
                <span className="paginator__pages">{`${currentPage} from ${Math.ceil(
                  userData.length / itemsPerPage
                )}`}</span>

                <ul className="paginator__paginator">
                  {Array.from({
                    length: Math.ceil(userData.length / itemsPerPage),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={currentPage === index + 1 ? "active" : ""}
                    >
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* <!-- end paginator --> */}
          </div>
        </div>
      </main>
      {/* <!-- end main content --> */}

      {/* <!-- modal status --> */}
      <div id="modal-status" className="zoom-anim-dialog mfp-hide modal">
        <h6 className="modal__title">Status change</h6>

        <p className="modal__text">
          Are you sure about immediately change status?
        </p>

        <div className="modal__btns">
          <button className="modal__btn modal__btn--apply" type="button">
            Apply
          </button>
          <button className="modal__btn modal__btn--dismiss" type="button">
            Dismiss
          </button>
        </div>
      </div>
      {/* <!-- end modal status --> */}

      {/* <!-- modal delete --> */}
      <div id="modal-delete" className="zoom-anim-dialog mfp-hide modal">
        <h6 className="modal__title">User delete</h6>

        <p className="modal__text">
          Are you sure to permanently delete this user?
        </p>

        <div className="modal__btns">
          <button className="modal__btn modal__btn--apply" type="button">
            Delete
          </button>
          <button className="modal__btn modal__btn--dismiss" type="button">
            Dismiss
          </button>
        </div>
      </div>
      {/* <!-- end modal delete --> */}
    </>
  );
}
