import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import axios from "axios";
import Swal from "sweetalert2";

export default function UserSchedule() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    axios
      .get(`https://church.webions.co.uk/backend/api/schedule/`)
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        // Handle errors
      
        Swal.fire({
					title: 'Error',
					text: error,
					icon: 'error',
					confirmButtonText: 'Okay'
				  });
      });
  }, []);

  const eventContent = (arg) => {
    return (
      <>
        <div className="d-flex  align-center cus-parent">
          <div
            className="d-flex ml-2"
            style={{ flexDirection: "column", width: "100%" }}
          >
            <div style={{ color: "white" }}>{arg.event._def.title}</div>

            <div style={{ color: "white" }}>
              {arg.event._def.extendedProps.time}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events ? events : []}
        eventContent={eventContent}
      />
    </>
  );
}
