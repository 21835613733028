import React from 'react'
import Forgot from '../Components/Forgot'

export default function Forgot_password() {
  return (
    <>
        <Forgot/> 
    </>
  )
}
