import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Home from "./Pages/Home";
import Live from "./Pages/Live";
import Contact from "./Pages/Contact";
import Signin from "./Pages/Signin";
import Signup from "./Components/Signup";
import Forgot_password from "./Pages/Forgot_password";
import Previous_streamings from "./Pages/Previous_streamings";
import Admin from "./Pages/Admin/Admin";
import BasicLayout from "./Layouts/BasicLayout";
import AdminLayout from "./Layouts/AdminLayout";
import Edit_users from "./Pages/Admin/Edit_users.jsx";
import UserList from "./Pages/Admin/UserList";
import Users from "./Pages/Admin/Users";
import UserLayout from "./Layouts/UserLayout";
import UsersDashbord from "./Pages/Users/UsersDashbord";
import LiveStream from "./Pages/Users/LiveStream";
import StreamingList from "./Pages/Users/StreamingList";
import EditUsers from "./Pages/Users/EditUsers";
import StreamShedule from "./Pages/Admin/StreamShedule";
import Loader from "./Components/Admin/Loader"; // Import your loader component
import PreviewStream from "./Pages/PreviewStream";
import RegistrationComplete from "./Pages/RegistrationComplete";
import ResetPassword from "./Pages/ResetPassword";
import { useSelector } from "react-redux";
import { getToken, removeToken } from "./services/LocalStorageService";
import axios from "axios";
import Error from "./Pages/Error";
import Swal from "sweetalert2";

function App() {
  const { pathname } = useLocation();
  const [routesLoaded, setRoutesLoaded] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    let scriptSource;

    if (pathname.includes("admin")) {
      scriptSource = `${window.location.origin}/admin.js`;
    } else if (pathname.includes("users")) {
      scriptSource = `${window.location.origin}/admin.js`;
    } else {
      scriptSource = `${window.location.origin}/main.js`;
    }

    const script = document.createElement("script");
    script.src = scriptSource;

    script.onload = () => {
      setRoutesLoaded(true);
    };

    script.onerror = () => {
      setRoutesLoaded(true);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [pathname]);

  useEffect(() => {
    if (pathname.includes("admin")) {
      require("../src/Admin.css");
      require("../src/frozen-globe.css");
    } else if (pathname.includes("users")) {
      require("../src/users.css");
      require("../src/frozen-globe.css");
    } else {
      require("../src/App.css");
    }
  }, [pathname]);
  // const { access_token } = useSelector(state => state.auth)
  const { access_token } = getToken();
  const [data, setData] = useState();
  useEffect(() => {
    if (access_token) {
      axios
        .get("https://church.webions.co.uk/backend/api/user/profile/", {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          setData(response.data);
          // dispatch(setUserInfo({
          // 	email: data.email,
          // 	name: data.name
          //   }))
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            removeToken();
          } else {
            Swal.fire({
              title: "Error",
              text: error,
              icon: "error",
              confirmButtonText: "Okay",
            });
          }
        });
    }
  }, []);
  useEffect(() => {
    if (routesLoaded) {
      setTimeout(() => {
        setShowError(true); // Set showError to true after 2 seconds
      }, 300);
    }
  }, [routesLoaded]);

  return (
    <>
      {!routesLoaded && <Loader />}
      <Routes>
        <Route element={<BasicLayout />} path="/">
          <Route element={<Home />} index />
          <Route
            element={<RegistrationComplete />}
            path="/registration-complete"
          />

          <Route element={<PreviewStream />} path="/PreviewStream" />
          <Route element={<Live />} path="/Live" />
          <Route element={<Contact />} path="/Contact" />
          <Route element={<Signin />} path="/Signin" />
          <Route element={<Signup />} path="/Signup" />
          <Route element={<Forgot_password />} path="/Forgot_password" />
          <Route element={<ResetPassword />} path="/reset/:id/:token" />
          <Route
            element={<Previous_streamings />}
            path="/Previous_streamings"
          />
        </Route>
        {/* <Admins> */}
        {data?.is_admin ? (
          <Route element={<AdminLayout />} path="/admin">
            <Route element={<Admin />} index />
            <Route element={<Edit_users />} path="/admin/Edit_user/:id" />
            {/* <Route element={<UserList />} path="/admin/UserList" /> */}
            <Route element={<StreamShedule />} path="/admin/stream-shedule" />
            <Route element={<Users />} path="/admin/User" />
          </Route>
        ) : (
          <Route element={<UserLayout />} path="/users">
            <Route element={<UsersDashbord />} index />
            <Route element={<LiveStream />} path="/users/live-stream" />
            {/* <Route element={<StreamingList />} path="/users/streaming-list" /> */}
            <Route element={<EditUsers />} path="/users/edit-users" />
          </Route>
        )}
        {showError && <Route element={<Error />} path="*" />}{" "}
      </Routes>
      {/* Conditional Error Route */}
    </>
  );
}

export default App;
