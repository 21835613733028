import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getToken, removeToken } from '../../services/LocalStorageService'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { setUserInfo, unsetUserInfo } from '../../features/userSlice'
import { setUserToken, unSetUserToken } from '../../features/authSlice'
import Swal from 'sweetalert2'

export default function UsersNavbar() {
	const { access_token } = getToken()
	const [data,setData] = useState({})
	const dispatch = useDispatch()
	const navigate = useNavigate()
	

	useEffect(() => {

	  
		axios
		  .get('https://church.webions.co.uk/backend/api/user/profile/', {
			headers: {
			  Authorization: `Bearer ${access_token}`,
		
			},
		  })
		  .then(response => {
			setData(response.data)
			dispatch(setUserInfo({
				email: data.email,
				name: data.name
			  }))
			  
		
		  })
		  .catch(error => {
		
			Swal.fire({
				title: 'Error',
				text: error,
				icon: 'error',
				confirmButtonText: 'Okay'
			  });
		  });
	  }, []);
	  const handleLogout = () => {
		dispatch(unsetUserInfo({ name: "", email: "" }))
		dispatch(unSetUserToken({ access_token: null }))
		removeToken()
		window.location.href = '/Signin'

		
	  }


  return (
    <>
        	{/* <!-- header --> */}
	<header className="header">
		<div className="header__content">
			{/* <!-- header logo --> */}
			<Link to={"/"} className="header__logo">
				<img src="/img/church-logo.png" alt=""/>
			</Link>
			{/* <!-- end header logo --> */}

			{/* <!-- header menu btn --> */}
			<button className="header__btn" type="button">
				<span></span>
				<span></span>
				<span></span>
			</button>
			{/* <!-- end header menu btn --> */}
		</div>
	</header>
	{/* <!-- end header --> */}

	{/* <!-- sidebar --> */}
	<div className="sidebar">
		{/* <!-- sidebar logo --> */}
		<Link to={"/"} className="sidebar__logo">
				<img src="/img/church-logo.png" alt=""/>
			</Link>
		{/* <!-- end sidebar logo --> */}
		
		{/* <!-- sidebar user --> */}
		<div className="sidebar__user">
			<div className="sidebar__user-img">
				<img src="/img/user.svg" alt=""/>
			</div>

			<div className="sidebar__user-title">
				<span>Users</span>
				<p>{`${data?.first_name} ${data?.last_name}`}</p>
			</div>

			<button className="sidebar__user-btn" type="button" onClick={handleLogout}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"/></svg>
			</button>
		</div>

		<ul className="sidebar__nav">
			<li className="sidebar__nav-item">
				<Link to="/users" className="sidebar__nav-link sidebar__nav-link--active"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20,8h0L14,2.74a3,3,0,0,0-4,0L4,8a3,3,0,0,0-1,2.26V19a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V10.25A3,3,0,0,0,20,8ZM14,20H10V15a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Zm5-1a1,1,0,0,1-1,1H16V15a3,3,0,0,0-3-3H11a3,3,0,0,0-3,3v5H6a1,1,0,0,1-1-1V10.25a1,1,0,0,1,.34-.75l6-5.25a1,1,0,0,1,1.32,0l6,5.25a1,1,0,0,1,.34.75Z"/></svg> <span>Dashboard</span></Link>
			</li>

			<li className="sidebar__nav-item">
				<Link to="/users/live-stream" className="sidebar__nav-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10,13H3a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14A1,1,0,0,0,10,13ZM9,20H4V15H9ZM21,2H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM20,9H15V4h5Zm1,4H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14A1,1,0,0,0,21,13Zm-1,7H15V15h5ZM10,2H3A1,1,0,0,0,2,3v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V3A1,1,0,0,0,10,2ZM9,9H4V4H9Z"/></svg> <span>Live Streaming</span></Link>
			</li>
			<li className="sidebar__nav-item">
				<Link to="/Previous_streamings" className="sidebar__nav-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19,5.5H12.72l-.32-1a3,3,0,0,0-2.84-2H5a3,3,0,0,0-3,3v13a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V8.5A3,3,0,0,0,19,5.5Zm1,13a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5.5a1,1,0,0,1,1-1H9.56a1,1,0,0,1,.95.68l.54,1.64A1,1,0,0,0,12,7.5h7a1,1,0,0,1,1,1Z"/></svg> <span>Streaming List</span></Link>
			</li>

			{/* <!-- collapse --> */}
			<li className="sidebar__nav-item">
				<Link className="sidebar__nav-link" to="/users/edit-users"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.3,12.22A4.92,4.92,0,0,0,14,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,1,19.5a1,1,0,0,0,2,0,6,6,0,0,1,12,0,1,1,0,0,0,2,0A8,8,0,0,0,12.3,12.22ZM9,11.5a3,3,0,1,1,3-3A3,3,0,0,1,9,11.5Zm9.74.32A5,5,0,0,0,15,3.5a1,1,0,0,0,0,2,3,3,0,0,1,3,3,3,3,0,0,1-1.5,2.59,1,1,0,0,0-.5.84,1,1,0,0,0,.45.86l.39.26.13.07a7,7,0,0,1,4,6.38,1,1,0,0,0,2,0A9,9,0,0,0,18.74,11.82Z"/></svg> <span>Edit Profile</span></Link>
			</li>


		
			{/* <li className="sidebar__nav-item">
				<a href="../index.html" className="sidebar__nav-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"/></svg> <span>Back to Church</span></a>
			</li> */}
		</ul>
		
		
		<div className="sidebar__copyright">© Church, 2023. <br/>Developed by <a href="https://theinceptives.com/" target="_blank">The Inceptives</a></div>
		
	</div>

    </>
  )
}
