import React from 'react'
import Navbar from '../Components/Navbar'
import Landing_hero from '../Components/Landing_hero'
import Streaming_slider from '../Components/Streaming_slider'

export default function Home() {
  return (
    <div>
      <Landing_hero/>
      <Streaming_slider/>
    </div>
  )
}
