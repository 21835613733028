import React from 'react'
import Login from '../Components/Login'

export default function Signin() {
  return (
    <>
        <Login/>
    </>
  )
}
