import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getToken, storeToken } from "../services/LocalStorageService";
import { setUserToken } from "../features/authSlice";
import Swal from "sweetalert2";

export default function Login() {
  const [error, setError] = useState();
  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const handleData = () => {
    axios
      .post("https://church.webions.co.uk/backend/api/user/login/", credentials)
      .then(function (response) {
        storeToken(response.data.token);
        let { access_token } = getToken();
        dispatch(setUserToken({ access_token: access_token }));
        if (response.data.admin) {
          window.location.href = "/admin";
        } else {
          window.location.href = "/users";
        }
      })
      .catch(function (error) {
        if (error.response.data.errors["pending_approval"]) {
          Swal.fire({
            title: "Error",
            text: "Your Account has not been approved",
            icon: "error",
            confirmButtonText: "Okay",
          });
          setError(error.response.data.errors);
        }
        Swal.fire({
          title: "Error",
          text: error.response.data.errors.non_field_errors,
          icon: "error",
          confirmButtonText: "Okay",
        });
        // alert("Your Account has not been approved");
      });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  let { access_token } = getToken();

  useEffect(() => {
    dispatch(setUserToken({ access_token: access_token }));
  }, [access_token, dispatch]);

  return (
    <>
      <div
        className="sign section--full-bg header_signing"
        data-bg="../img/bg1.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sign__content">
                <form action="#" className="sign__form">
                  <Link
                    to={"/"}
                    style={{ marginBottom: "40px" }}
                    className="sign__logo"
                  >
                    <img src="/img/church-logo.png" alt="" />
                  </Link>

                  <div className="sign__group">
                    <input
                      type="text"
                      className="sign__input"
                      onChange={handleChange}
                      value={credentials.email}
                      name="email"
                      placeholder="Email"
                    />
                  </div>

                  <div className="sign__group">
                    <input
                      type="password"
                      className="sign__input"
                      onChange={handleChange}
                      value={credentials.password}
                      name="password"
                      placeholder="Password"
                    />
                  </div>

                  {/* <div className="sign__group sign__group--checkbox">
                    <input
                      id="remember"
                      name="remember"
                      type="checkbox"
                      checked="checked"
                    />
                    <label for="remember">Remember Me</label>
                  </div> */}

                  <button
                    className="sign__btn"
                    onClick={handleData}
                    type="button"
                  >
                    Sign in
                  </button>

                  <span className="sign__delimiter">or</span>

                  <span className="sign__text">
                    Don't have an account? <Link to="/Signup">Sign up!</Link>
                  </span>

                  <span className="sign__text">
                    <Link to="/Forgot_password">Forgot password?</Link>
                  </span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
