import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Admin() {
  const [videos, setVideos] = useState();
  const [userData, setUserData] = useState([]);
  const sortedUserData = userData.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  // Take the most recent 5 registrations
  const recentRegistrations = sortedUserData.slice(0, 5);
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const res = await fetch(
          `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=25&playlistId=${process.env.REACT_APP_YOUTUBE_PLAYLIST_ID}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
        )
          .then((data) => data.json())
          .then((data) => data.items);
    
        setVideos(res);
    
      } catch (ex) {}
    };

    fetchVideos();
  }, []);
  useEffect(() => {
    axios.get("https://church.webions.co.uk/backend/api/user/").then(function (response) {
      setUserData(response.data);
    });
  }, []);

  return (
    <>
      <main className="main">
        <div className="container-fluid">
          <div className="row">
            {/* <!-- main title --> */}
            <div className="col-12">
              <div className="main__title">
                <h2>Dashboard</h2>
              </div>
            </div>
            {/* <!-- end main title --> */}

            {/* <!-- stats --> */}
            <div className="col-12 col-sm-6 col-xl-3">
              <div className="stats">
                <span>Total Streams</span>
                <p>{videos?.length}</p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M21.92,11.6C19.9,6.91,16.1,4,12,4S4.1,6.91,2.08,11.6a1,1,0,0,0,0,.8C4.1,17.09,7.9,20,12,20s7.9-2.91,9.92-7.6A1,1,0,0,0,21.92,11.6ZM12,18c-3.17,0-6.17-2.29-7.9-6C5.83,8.29,8.83,6,12,6s6.17,2.29,7.9,6C18.17,15.71,15.17,18,12,18ZM12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" />
                </svg>
              </div>
            </div>
            {/* <!-- end stats --> */}

            {/* <!-- stats --> */}
            <div className="col-12 col-sm-6 col-xl-3">
              <div className="stats">
                <span>Streams This Month</span>
                <p>
                  {(() => {
                    const today = new Date();
                    const thisMonth = today.getMonth() + 1;
                    const streamsThisMonth = videos?.reduce((count, video) => {
                      const videoDate = new Date(video?.snippet.publishedAt);
                      if (videoDate.getMonth() + 1 === thisMonth) {
                        return count + 1;
                      }
                      return count;
                    }, 0);

                    return streamsThisMonth;
                  })()}
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M10,13H4a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V14A1,1,0,0,0,10,13ZM9,19H5V15H9ZM20,3H14a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,20,3ZM19,9H15V5h4Zm1,7H18V14a1,1,0,0,0-2,0v2H14a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V18h2a1,1,0,0,0,0-2ZM10,3H4A1,1,0,0,0,3,4v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,10,3ZM9,9H5V5H9Z" />
                </svg>
              </div>
            </div>
            {/* <!-- end stats --> */}

            {/* <!-- stats --> */}
            <div className="col-12 col-sm-6 col-xl-3">
              <div className="stats">
                <span>Total Users</span>
                <p>{userData?.length}</p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M8,11a1,1,0,1,0,1,1A1,1,0,0,0,8,11Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,12,11Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,16,11ZM12,2A10,10,0,0,0,2,12a9.89,9.89,0,0,0,2.26,6.33l-2,2a1,1,0,0,0-.21,1.09A1,1,0,0,0,3,22h9A10,10,0,0,0,12,2Zm0,18H5.41l.93-.93a1,1,0,0,0,.3-.71,1,1,0,0,0-.3-.7A8,8,0,1,1,12,20Z" />
                </svg>
              </div>
            </div>
            {/* <!-- end stats --> */}

            {/* <!-- stats --> */}
            <div className="col-12 col-sm-6 col-xl-3">
              <div className="stats">
                <span>Users Registered This Month</span>
                <p>
                  {(() => {
                    const today = new Date();
                    const thisMonth = today.getMonth() + 1;
                    const usersThisMonth = userData.reduce((count, user) => {
                      const userDate = new Date(user.created_at);
                      if (userDate.getMonth() + 1 === thisMonth) {
                        return count + 1;
                      }
                      return count;
                    }, 0);

                    return usersThisMonth;
                  })()}
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M22,9.67A1,1,0,0,0,21.14,9l-5.69-.83L12.9,3a1,1,0,0,0-1.8,0L8.55,8.16,2.86,9a1,1,0,0,0-.81.68,1,1,0,0,0,.25,1l4.13,4-1,5.68A1,1,0,0,0,6.9,21.44L12,18.77l5.1,2.67a.93.93,0,0,0,.46.12,1,1,0,0,0,.59-.19,1,1,0,0,0,.4-1l-1-5.68,4.13-4A1,1,0,0,0,22,9.67Zm-6.15,4a1,1,0,0,0-.29.88l.72,4.2-3.76-2a1.06,1.06,0,0,0-.94,0l-3.76,2,.72-4.2a1,1,0,0,0-.29-.88l-3-3,4.21-.61a1,1,0,0,0,.76-.55L12,5.7l1.88,3.82a1,1,0,0,0,.76.55l4.21.61Z" />
                </svg>
              </div>
            </div>
            {/* <!-- end stats --> */}

            {/* <!-- dashbox --> */}
            <div className="col-12 col-xl-6">
              <div className="dashbox">
                <div className="dashbox__title">
                  <h3>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M12,6a1,1,0,0,0-1,1V17a1,1,0,0,0,2,0V7A1,1,0,0,0,12,6ZM7,12a1,1,0,0,0-1,1v4a1,1,0,0,0,2,0V13A1,1,0,0,0,7,12Zm10-2a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V11A1,1,0,0,0,17,10Zm2-8H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z" />
                    </svg>{" "}
                    Recently Registered Users
                  </h3>

                  <div className="dashbox__wrap">
                    
                    <Link className="dashbox__more" to="/admin/UserList">
                      View All
                    </Link>
                  </div>
                </div>

                <div className="dashbox__table-wrap dashbox__table-wrap--1">
                  <table className="main__table main__table--dash">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>DATE OF REGISTRATION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentRegistrations.map((user) => (
                        <tr key={user.id}>
                          <td>
                            <div className="main__table-text">{user.id}</div>
                          </td>
                          <td>
                            <div className="main__table-text">
                              <a href="#">{`${user.first_name} ${user.last_name}`}</a>
                            </div>
                          </td>
                          <td>
                            <div className="main__table-text">
                              <a href="#">{user.email}</a>
                            </div>
                          </td>
                          <td>
                            <div className="main__table-text">
                              {new Date(user.created_at).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <!-- end dashbox --> */}

            {/* <!-- dashbox --> */}
            <div className="col-12 col-xl-6">
              <div className="dashbox">
                <div className="dashbox__title">
                  <h3>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M10,13H3a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14A1,1,0,0,0,10,13ZM9,20H4V15H9ZM21,2H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM20,9H15V4h5Zm1,4H14a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V14A1,1,0,0,0,21,13Zm-1,7H15V15h5ZM10,2H3A1,1,0,0,0,2,3v7a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V3A1,1,0,0,0,10,2ZM9,9H4V4H9Z" />
                    </svg>
                    Recent Streams
                  </h3>

                  <div className="dashbox__wrap">
                    
                    <a className="dashbox__more" href="catalog.html">
                      View All
                    </a>
                  </div>
                </div>

                <div className="dashbox__table-wrap dashbox__table-wrap--2">
                  <table className="main__table main__table--dash">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>TITLE</th>
                        <th>PUBLISHED AT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {videos
                        ?.filter(
                          (video) => video.snippet.title !== "Deleted video"
                        )
                        .slice(0, 5)
                        .map((video, index) => (
                          <tr key={index + 1}>
                            <td>
                              <div className="main__table-text">
                                {index + 1}
                              </div>
                            </td>
                            <td>
                              <div className="main__table-text">
                                <a href="#">
                                  {video.snippet.title.length > 20 // Adjust the length as needed
                                    ? `${video.snippet.title.slice(0, 20)}...`
                                    : video.snippet.title}
                                </a>
                              </div>
                            </td>
                            <td>
                              <div className="main__table-text">
                                {new Date(
                                  video.snippet.publishedAt
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <!-- end dashbox --> */}
          </div>
        </div>
      </main>
    </>
  );
}
