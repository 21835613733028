import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getToken, removeToken } from "../services/LocalStorageService";
import { unsetUserInfo } from "../features/userSlice";
import { unSetUserToken } from "../features/authSlice";
import axios from "axios";
import Swal from "sweetalert2";
export default function Navbar() {
  const { access_token } = getToken();
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (access_token) {
      axios
        .get("https://church.webions.co.uk/backend/api/user/profile/", {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            removeToken();
          } else {
            Swal.fire({
              title: "Error",
              text: error,
              icon: "error",
              confirmButtonText: "Okay",
            });
          }
        });
    }
  }, []);

  const handleLogout = () => {
    dispatch(unsetUserInfo({ name: "", email: "" }));
    dispatch(unSetUserToken({ access_token: null }));
    removeToken();
    window.location.href = "/Signin";
  };

  const navigateUser = () => {
    if (data.is_admin) {
      navigate("/admin");
    } else {
      navigate("/users");
    }
  };
  const admin_pages = [
    { name: "Admin Dashboard", url: "/admin" },

    { name: "Stream Schedule", url: "/admin/stream-shedule" },
    { name: "Previous Streams", url: "/Previous_streamings" },
    { name: "Home Page", url: "/" },
    { name: "Contact Form", url: "/Contact" },
  ];

  const user_pages = [
    { name: "User Dashboard", url: "/users" },
    { name: "User Profile", url: "/users/edit-users" },

    { name: "Live Stream", url: "/users/live-stream" },
    { name: "Streaming List", url: "/Previous_streamings" },
    { name: "Home Page", url: "/" },
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPages, setFilteredPages] = useState([]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Filter pages based on the search term
    if (data.is_admin) {
      const filteredAdminPages = admin_pages.filter((page) =>
        page.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredPages([...filteredAdminPages]);
    } else {
      const filteredUserPages = user_pages.filter((page) =>
        page.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredPages([...filteredUserPages]);
    }
  };

  const handlePageClick = (url) => {
    // Redirect to the selected page
    navigate(url);

    // Reset the input and filtered list
    setSearchTerm("");
    setFilteredPages([]);
  };
  return (
    <>
      <header className="header header--static d-block">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-12">
              <div className="header__content">
                <button className="header__menu" type="button">
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <Link to="/" className="header__logo">
                  <img src="/img/church-logo.png" alt="" />
                </Link>
                <ul className="header__nav">
                  <li className="header__nav-item">
                    <Link
                      className="header__nav-link header__nav-link--live"
                      to="/Live"
                    >
                      LIVE
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="6"
                          cy="4"
                          r="4"
                          fill="#EB5757"
                          fill-opacity="0.5"
                        />
                        <g filter="url(#filter0_d)">
                          <circle cx="6" cy="4" r="2" fill="#EB5757" />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d"
                            x="0"
                            y="0"
                            width="12"
                            height="12"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            />
                            <feOffset dy="2" />
                            <feGaussianBlur stdDeviation="2" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </Link>
                  </li>
                  <li className="header__nav-item">
                    <a
                      className="header__nav-link header__nav-link--more"
                      href="#"
                      role="button"
                      id="dropdownMenu3"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6.93893 14.3033C6.08141 14.3033 5.38477 13.6067 5.38477 12.7505C5.38477 11.8943 6.08141 11.1963 6.93893 11.1963C7.79644 11.1963 8.49309 11.8943 8.49309 12.7505C8.49309 13.6067 7.79644 14.3033 6.93893 14.3033Z" />
                        <path d="M12.7501 14.3033C11.8926 14.3033 11.1959 13.6067 11.1959 12.7505C11.1959 11.8943 11.8926 11.1963 12.7501 11.1963C13.6076 11.1963 14.3042 11.8943 14.3042 12.7505C14.3042 13.6067 13.6076 14.3033 12.7501 14.3033Z" />
                        <path d="M18.5608 14.3033C17.7032 14.3033 17.0066 13.6067 17.0066 12.7505C17.0066 11.8943 17.7032 11.1963 18.5608 11.1963C19.4183 11.1963 20.1149 11.8943 20.1149 12.7505C20.1149 13.6067 19.4183 14.3033 18.5608 14.3033Z" />
                      </svg>
                    </a>

                    <ul className="dropdown-menu header__nav-menu header__nav-menu--scroll">
                      <li>
                        <Link to="/Previous_streamings">
                          Previous Streamings
                        </Link>
                      </li>
                      <li>
                        <Link to="/Contact">Contact Us</Link>
                      </li>
                    </ul>
                  </li>
                </ul>

                <div className="header__actions">
                  <form action="#" className="header__form">
                    <input
                      className="header__form-input"
                      type="text"
                      placeholder="I'm looking for..."
                      value={searchTerm}
                      onChange={handleInputChange}
                    />
                    <button className="header__form-btn" type="button">
                      {/* Add your search icon SVG here */}
                    </button>

                    {/* Display the filtered pages as a dropdown */}
                    {filteredPages.length > 0 && (
                      <ul
                        className={`dropdown-menu header__nav-menu header__nav-menu--scroll ${searchTerm ?'show': ''}`}
                      >
                        {filteredPages.map((page, index) => (
                          <li
                            key={index}
                            style={{ color: "white" ,cursor:'pointer'}}
                            onClick={() => handlePageClick(page.url)}
                          >
                            <span>{page.name}</span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </form>

                  <button className="header__search" type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" />
                    </svg>
                  </button>

                  {access_token ? (
                    <>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={handleLogout}
                        className="header__user"
                      >
                        <span>Sign Out</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20,12a1,1,0,0,0-1-1H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H19A1,1,0,0,0,20,12ZM17,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V16a1,1,0,0,0-2,0v3a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V8a1,1,0,0,0,2,0V5A3,3,0,0,0,17,2Z" />
                        </svg>
                      </a>
                      <i
                        class="fa-regular fa-circle-user user-icon"
                        onClick={navigateUser}
                      ></i>
                    </>
                  ) : (
                    <>
                      <Link to="/Signin" className="header__user">
                        <span>Sign In</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20,12a1,1,0,0,0-1-1H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H19A1,1,0,0,0,20,12ZM17,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V16a1,1,0,0,0-2,0v3a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V8a1,1,0,0,0,2,0V5A3,3,0,0,0,17,2Z" />
                        </svg>
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
