import React from "react";
import UserSchedule from "../../Components/Users/UserSchedule";

export default function UsersDashbord() {
  return (
    <>
      <main className="main">
        <div className="container-fluid">
          <div className="row justify-content-center">
            {/* <!-- main title --> */}
            <div className="col-12">
              <div className="main__title">
                <h2>Dashboard</h2>
                <div className="main__title-wrap">
                  {/* <!-- search --> */}
              
                  {/* <!-- end search --> */}
                </div>
              </div>
            </div>
            {/* <!-- end main title --> */}

            <div className="col-lg-12">
              <UserSchedule/>
            </div>
            {/* <!-- end dashbox --> */}
           
          </div>
        </div>
      </main>
    </>
  );
}
