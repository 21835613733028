import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { unsetUserInfo } from "../../features/userSlice";
import { unSetUserToken } from "../../features/authSlice";
import { getToken, removeToken } from "../../services/LocalStorageService";
import Swal from 'sweetalert2';

export default function Edit_users() {
  const params = useParams();
  const dispatch = useDispatch();

  const [userData, setUserData] = useState();
  const [passwords, setPasswords] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`https://church.webions.co.uk/backend/api/user/${params.id}/`)
      .then(function (response) {
        setUserData(response.data);
      });
  }, []);
  function deleteUser(id) {
    axios
      .delete(`https://church.webions.co.uk/backend/api/user/${id}/`)
      .then(function (response) {
        // alert("User has been Deleted");
        Swal.fire({
					title: 'Success',
					text: "User has been Deleted",
					icon: 'success',
					confirmButtonText: 'Okay'
				  });
        navigate("/admin/User/");
      });
  }


  const handleDataSubmit = () => {
    

    axios
      .put(
        `https://church.webions.co.uk/backend/api/user/${params.id}/`,
        { first_name: userData?.first_name, last_name: userData?.last_name }
       
      )
      .then((response) => {
        dispatch(unsetUserInfo({ name: "", email: "" }));
        dispatch(unSetUserToken({ access_token: null }));
        removeToken();
        Swal.fire({
          title: 'Success',
          text: response.data.msg,
          icon: 'success',
          confirmButtonText: 'Okay'
          });
        // alert(response.data.msg);
        window.location.href = "/Signin";
      });
  };

  const handleDataChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <>
      <main className="main">
        <div className="container-fluid">
          <div className="row">
            {/* <!-- main title --> */}
            <div className="col-12">
              <div className="main__title">
                <h2>Edit user</h2>
              </div>
            </div>

            <div className="col-12">
              <div className="profile__content">
                <div className="profile__user">
                  <div className="profile__avatar">
                    <img src="/img/user.svg" alt="" />
                  </div>
                  {/* <!-- or red --> */}
                  <div
                    className={`profile__meta ${
                      userData?.status === "approved"
                        ? "profile__meta--green"
                        : "profile__meta--red"
                    }`}
                  >
                    <h3>
                      {`${userData?.first_name} ${userData?.last_name}`}{" "}
                      <span>({userData?.status})</span>
                    </h3>
                    <span>Member ID: {userData?.id}</span>
                  </div>
                </div>

                <ul
                  className="nav nav-tabs profile__tabs"
                  id="profile__tabs"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#tab-1"
                      role="tab"
                      aria-controls="tab-1"
                      aria-selected="true"
                    >
                      Profile
                    </a>
                  </li>
                </ul>
                {/* <!-- end profile tabs nav --> */}

                {/* <!-- profile mobile tabs nav --> */}
                <div className="profile__mobile-tabs" id="profile__mobile-tabs">
                  <div
                    className="profile__mobile-tabs-btn dropdown-toggle"
                    role="navigation"
                    id="mobile-tabs"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <input type="button" value="Profile" />
                    <span></span>
                  </div>

                  <div
                    className="profile__mobile-tabs-menu dropdown-menu"
                    aria-labelledby="mobile-tabs"
                  >
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="1-tab"
                          data-toggle="tab"
                          href="#tab-1"
                          role="tab"
                          aria-controls="tab-1"
                          aria-selected="true"
                        >
                          Profile
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="2-tab"
                          data-toggle="tab"
                          href="#tab-2"
                          role="tab"
                          aria-controls="tab-2"
                          aria-selected="false"
                        >
                          Comments
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="3-tab"
                          data-toggle="tab"
                          href="#tab-3"
                          role="tab"
                          aria-controls="tab-3"
                          aria-selected="false"
                        >
                          Reviews
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- end profile mobile tabs nav --> */}

                {/* <!-- profile btns --> */}
                <div className="profile__actions">
                  <a
                    href="#"
                    className="profile__action profile__action--banned"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M12,13a1.49,1.49,0,0,0-1,2.61V17a1,1,0,0,0,2,0V15.61A1.49,1.49,0,0,0,12,13Zm5-4V7A5,5,0,0,0,7,7V9a3,3,0,0,0-3,3v7a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V12A3,3,0,0,0,17,9ZM9,7a3,3,0,0,1,6,0V9H9Zm9,12a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H17a1,1,0,0,1,1,1Z" />
                    </svg>
                  </a>
                  <a
                    href="#"
                    className="profile__action profile__action--delete "
                    onClick={() => deleteUser(params.id)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" />
                    </svg>
                  </a>
                </div>
                {/* <!-- end profile btns --> */}
              </div>
            </div>
            {/* <!-- end profile --> */}

            {/* <!-- content tabs --> */}
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="tab-1"
                role="tabpanel"
                aria-labelledby="1-tab"
              >
                <div className="col-12">
                  <div className="sign__wrap">
                    <div className="row">
                      {/* <!-- details form --> */}
                      <div className="col-12 col-lg-6">
                        <form
                          action="#"
                          className="sign__form sign__form--profile sign__form--first"
                        >
                          <div className="row">
                            <div className="col-12">
                              <h4 className="sign__title">Profile details</h4>
                            </div>

                            <div className="col-12 col-md-6 col-lg-12 col-xl-6">
                              <div className="sign__group">
                                <label className="sign__label" for="email">
                                  Email
                                </label>
                                <input
                                  id="email"
                                  type="text"
                                  name="email"
                                  className="sign__input"
                                  placeholder={userData?.email}
                                  disabled
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-12 col-xl-6">
                              <div className="sign__group">
                                <label className="sign__label" for="firstname">
                                  First Name
                                </label>
                                <input
                                  id="firstname"
                                  type="text"
                                  name="first_name"
                                  onChange={handleDataChange}
                                  className="sign__input"
                                  placeholder={userData?.first_name}
                                  value={userData?.first_name}
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-12 col-xl-6">
                              <div className="sign__group">
                                <label className="sign__label" for="lastname">
                                  Last name
                                </label>
                                <input
                                  id="lastname"
                                  type="text"
                                  name="last_name"
                                  onChange={handleDataChange}

                                  className="sign__input"
                                  placeholder={userData?.last_name}
                                  value={userData?.last_name}
                                />
                              </div>
                            </div>

                            <div className="col-12">
                              <button
                                className="sign__btn"
                               
                                type="button"
                                onClick={handleDataSubmit}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/* <!-- end details form --> */}

                      
                      {/* <!-- end password form --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end content tabs --> */}
          </div>
        </div>
      </main>

      {/* <!-- modal view --> */}
      <div
        id="modal-view"
        className="zoom-anim-dialog mfp-hide modal modal--view"
      >
        <div className="comments__autor">
          <img className="comments__avatar" src="/img/user.svg" alt="" />
          <span className="comments__name">John Doe</span>
          <span className="comments__time">30.08.2018, 17:53</span>
        </div>
        <p className="comments__text">
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly believable.
          If you are going to use a passage of Lorem Ipsum, you need to be sure
          there isn't anything embarrassing hidden in the middle of text.
        </p>
        <div className="comments__actions">
          <div className="comments__rate">
            <span>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 7.3273V14.6537"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.6667 10.9905H7.33333"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.6857 1H6.31429C3.04762 1 1 3.31208 1 6.58516V15.4148C1 18.6879 3.0381 21 6.31429 21H15.6857C18.9619 21 21 18.6879 21 15.4148V6.58516C21 3.31208 18.9619 1 15.6857 1Z"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>{" "}
              12
            </span>

            <span>
              7
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.6667 10.9905H7.33333"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.6857 1H6.31429C3.04762 1 1 3.31208 1 6.58516V15.4148C1 18.6879 3.0381 21 6.31429 21H15.6857C18.9619 21 21 18.6879 21 15.4148V6.58516C21 3.31208 18.9619 1 15.6857 1Z"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      {/* <!-- end modal view --> */}

      {/* <!-- modal delete --> */}
      <div id="modal-delete" className="zoom-anim-dialog mfp-hide modal">
        <h6 className="modal__title">Comment delete</h6>

        <p className="modal__text">
          Are you sure to permanently delete this comment?
        </p>

        <div className="modal__btns">
          <button className="modal__btn modal__btn--apply" type="button">
            Delete
          </button>
          <button className="modal__btn modal__btn--dismiss" type="button">
            Dismiss
          </button>
        </div>
      </div>
      {/* <!-- end modal delete --> */}

      {/* <!-- modal view --> */}
      <div
        id="modal-view2"
        className="zoom-anim-dialog mfp-hide modal modal--view"
      >
        <div className="reviews__autor">
          <img className="reviews__avatar" src="/img/user.svg" alt="" />
          <span className="reviews__name">Best Marvel movie in my opinion</span>
          <span className="reviews__time">24.08.2018, 17:53 by John Doe</span>

          <span className="reviews__rating">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M22,9.67A1,1,0,0,0,21.14,9l-5.69-.83L12.9,3a1,1,0,0,0-1.8,0L8.55,8.16,2.86,9a1,1,0,0,0-.81.68,1,1,0,0,0,.25,1l4.13,4-1,5.68A1,1,0,0,0,6.9,21.44L12,18.77l5.1,2.67a.93.93,0,0,0,.46.12,1,1,0,0,0,.59-.19,1,1,0,0,0,.4-1l-1-5.68,4.13-4A1,1,0,0,0,22,9.67Zm-6.15,4a1,1,0,0,0-.29.88l.72,4.2-3.76-2a1.06,1.06,0,0,0-.94,0l-3.76,2,.72-4.2a1,1,0,0,0-.29-.88l-3-3,4.21-.61a1,1,0,0,0,.76-.55L12,5.7l1.88,3.82a1,1,0,0,0,.76.55l4.21.61Z" />
            </svg>{" "}
            8.4
          </span>
        </div>
        <p className="reviews__text">
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly believable.
          If you are going to use a passage of Lorem Ipsum, you need to be sure
          there isn't anything embarrassing hidden in the middle of text.
        </p>
      </div>
      {/* <!-- end modal view --> */}

      {/* <!-- modal delete --> */}
      <div id="modal-delete2" className="zoom-anim-dialog mfp-hide modal">
        <h6 className="modal__title">Review delete</h6>

        <p className="modal__text">
          Are you sure to permanently delete this review?
        </p>

        <div className="modal__btns">
          <button className="modal__btn modal__btn--apply" type="button">
            Delete
          </button>
          <button className="modal__btn modal__btn--dismiss" type="button">
            Dismiss
          </button>
        </div>
      </div>
      {/* <!-- end modal delete --> */}

      {/* <!-- modal status --> */}
      <div id="modal-status3" className="zoom-anim-dialog mfp-hide modal">
        <h6 className="modal__title">Status change</h6>

        <p className="modal__text">
          Are you sure about immediately change status?
        </p>

        <div className="modal__btns">
          <button className="modal__btn modal__btn--apply" type="button">
            Apply
          </button>
          <button className="modal__btn modal__btn--dismiss" type="button">
            Dismiss
          </button>
        </div>
      </div>
      {/* <!-- end modal status --> */}

      {/* <!-- modal delete --> */}
      <div id="modal-delete3" className="zoom-anim-dialog mfp-hide modal">
        <h6 className="modal__title">User delete</h6>

        <p className="modal__text">
          Are you sure to permanently delete this user?
        </p>

        <div className="modal__btns">
          <button className="modal__btn modal__btn--apply" type="button">
            Delete
          </button>
          <button className="modal__btn modal__btn--dismiss" type="button">
            Dismiss
          </button>
        </div>
      </div>
      {/* <!-- end modal delete --> */}
    </>
  );
}
