import React from 'react'
import Hero_section from '../Components/Hero_section'

export default function Live() {
  return (
    <>
        <Hero_section/>
    </>
  )
}
