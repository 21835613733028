import React, { useEffect, useRef, useState } from "react";
// import Axios from "axios";
import { useVideo } from "../Context/VideoContext";
// import io from 'socket.io-client';
// import cheerio from 'cheerio';
// import puppeteer from 'puppeteer';
// import axios from 'axios';

const liveStreamLink = `https://www.youtube.com/embed/live_stream?channel=${process.env.REACT_APP_YOUTUBE_CHANNEL_ID}`;
 

export default function HeroSection() {

 

 





  return (
    <>
      <section className="hero_section">
        <div className="container-fluid p-0" style={{ position: "relative",display:'flex' ,justifyContent:'center',marginBottom:'40px' }}>

          <iframe width="1350" height="480" src={liveStreamLink}
            frameborder="0"
            allow="autoplay; clipboard-write; encrypted-media; gyroscope;"></iframe>
       

       
        </div>
      </section>
    </>
  );
}
