import React from 'react'

export default function RegistrationComplete() {
  return (
    <>
    <div className='d-flex justify-content-center align-items-center' style={{flexDirection:'column',height:'80vh'}}>

    <h1 style={{color:'white'}}>You have been registered successfully.</h1>
    <h5 style={{color:'white'}}> Wait for The Admin to Approve.</h5>
    <h5 style={{color:'white'}}>Once Approved, You will Recieve an Email.</h5>
    </div>
    </>
  )
}
