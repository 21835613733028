import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
export default function Forgot() {
	const [email,setEmail] = useState()
	const [error,setError] =useState()
	const navigate = useNavigate()
	const handleChange = (event) => {
		const { name,value} = event.target;
		setEmail((prevEmail) => ({
			...prevEmail,
			[name]: value,
		  }));

	  };
	  const handleSubmit = () => {
		axios
		  .post(
			`https://church.webions.co.uk/backend/api/user/send-reset-password-email/`,
			email,
			{
			  headers: {
				"Content-type": "application/json",
			  },
			}
		  )
		  .then((response) => {
			Swal.fire({
				title: 'Success',
				text: response.data.msg,
				icon: 'success',
				confirmButtonText: 'Okay'
			  });
			// alert(response.data.msg);
			window.location.href =  "/Signin";
		  })
		  .catch(function (error) {
			if (error.response.data.errors["non_field_errors"]) {
				Swal.fire({
					title: 'Error',
					text: error.response.data.errors["non_field_errors"],
					icon: 'error',
					confirmButtonText: 'Okay'
				  });
			//   alert(error.response.data.errors["non_field_errors"]);
			//   setError(error.response.data.errors);
			}
			Swal.fire({
				title: 'Error',
				text: error.response.data.errors,
				icon: 'error',
				confirmButtonText: 'Okay'
			  });
			setError(error.response.data.errors);
		  });
	  };
  return (
    <>
        	<div className="sign section--full-bg header_signing" data-bg="/img/bg1.jpg">
		<div className="container">
			<div className="row">
				<div className="col-12">
					<div className="sign__content">
						
						<form action="#" className="sign__form">
							<Link to="/" className="sign__logo">
								<img src="/img/church-logo.png" alt=""/>
							</Link>

							<div className="sign__group">
								<input type="text" className="sign__input" name="email" onChange={handleChange} value={email?.email} placeholder="Email"/>
							</div>

							<div className="sign__group sign__group--checkbox">
								<input id="remember" name="remember" type="checkbox" checked="checked"/>
								<label for="remember">I agree to the <a>Privacy Policy</a></label>
							</div>
							
							<button className="sign__btn" onClick={handleSubmit} type="button">Send</button>

							<span className="sign__text">We will send a password to your Email</span>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
    </>
  )
}
