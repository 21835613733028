import React, { useEffect, useRef, useState } from "react";
// import Axios from "axios";
import { Link, useNavigate } from "react-router-dom";

export default function Landing_hero() {
  return (
    <>
      <section className="hero_section">
        <div
          className="sign section--full-bg header_signing"
          data-bg="/img/bg1.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sign__content flex-column">
                  <a href="index.html" className="sign__logo">
                    <img src="/img/church-logo.png" alt="" />
                  </a>
                  <h1 className="text-white mt-3 text-center">
                    Welcome To Church Live Streaming
                  </h1>

                  <span className="sign__delimiter mt-4">
                    To Watch Live Stream Please <Link to="/Signin">Sign in!</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
