import React from "react";
import "./Loader.css"; // You can create a CSS file for styling the loader

function Loader() {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );    
}

export default Loader;
