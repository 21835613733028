import React from "react";
import { Link } from "react-router-dom";

export default function Error() {
  return (
    <>
      <div className="pure">
        <div className="c">
          <div className="_404">404</div>
          <hr />
          <div className="_1 mb-3">THE PAGE</div>
          <div className="_2 mb-3">WAS NOT FOUND</div>
          <Link className="error_button mb-3" to="/">
            BACK TO HOME PAGE
          </Link>
        </div>
      </div>
    </>
  );
}
